<template>
  <div class="hot-jobs">
    <div class="h-title">热招职位</div>
    <el-tabs class="h-tabs" @tab-click="handleClick">
      <el-tab-pane label="全部" name="">
        <ul class="h-list" v-loading="loading">
          <li class="l-item" v-for="(item, index) in jobItem" :key="index" @click="toJobDetail(item.id)">
            <div class="i-info">
              <ul class="i-top">
                <li class="jobName">{{ item.job_name }}</li>
                <li class="t-money" v-if="item.min_salary_range ==0 || item.max_salary_range ==0">面议</li>
                <li class="t-money" v-else>{{ item.min_salary_range }}-{{ item.max_salary_range}}k</li>
              </ul>
              <ul class="i-bottom">
                <li class="b-item">{{ item.place_of_work }}</li>
                <li class="b-item">招聘{{ item.job_num }}人</li>
                <!-- <li class="b-item">{{ item.education }}</li> -->
              </ul>
            </div>
            <ul class="i-company">
              <li class="c-item">
                <el-image :src="item.user_avatar" v-if="item.user_avatar" fit="scale-down"></el-image>
                <el-image v-else>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <p v-if="item.user_nickname">{{ item.user_nickname }}</p>
                <p v-else>十二方用户</p>
                <!-- <div class="c-text">{{ item.user_nickname }}</div> -->
                <el-image v-if="item.cert_company == 2" :src="require('@/assets/images/rz02.png')" class="enterprise"></el-image>
                <el-image v-else :src="require('@/assets/images/rz01.png')" class="enterprise"></el-image>
                <!-- <div class="c-type">{{ item.info.financing_stage }}</div> -->

                <el-image v-if="item.user_auth_state == 3" :src="require('@/assets/images/auth1.png')" class="auth"></el-image>
                <el-image v-else :src="require('@/assets/images/auth2.png')" class="auth"></el-image>
              </li>
            </ul>
          </li>
        </ul>
      </el-tab-pane>
      <el-tab-pane :label="item.unit_nature" :name="index" v-for="(item, index) in jobList" :key="index">
        <ul class="h-list" v-loading="loading">
          <li class="l-item" v-for="(item, index) in jobItem" :key="index" @click="toJobDetail(item.id)">
            <div class="i-info">
              <ul class="i-top">
                <li>{{ item.job_name }}</li>
                <li class="t-money" v-if="item.min_salary_range ==0 || item.max_salary_range ==0">面议</li>
                <li class="t-money" v-else>{{ item.min_salary_range }}-{{ item.max_salary_range}}k</li>
              </ul>
              <ul class="i-bottom">
                <li class="b-item">{{ item.place_of_work }}</li>
                <li class="b-item">招聘{{ item.job_num }}人</li>
                <!-- <li class="b-item">{{ item.education }}</li> -->
              </ul>
            </div>
            <ul class="i-company">
              <li class="c-item">
                <el-image :src="item.user_avatar" v-if="item.user_avatar" fit="scale-down">
                </el-image>
                <el-image v-else>
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <p v-if="item.user_nickname">{{ item.user_nickname }}</p>
                <p v-else>{{ item.user_nickname }}</p>
                <div class="c-text">十二方用户</div>
                <!-- <div class="c-type">{{ item.info.financing_stage }}</div> -->

                <el-image v-if="item.cert_company == 2" :src="require('@/assets/images/rz02.png')" class="enterprise"></el-image>
                <el-image v-else :src="require('@/assets/images/rz01.png')" class="enterprise"></el-image>

                <el-image v-if="item.user_auth_state == 3" :src="require('@/assets/images/auth1.png')" class="auth"></el-image>
                <el-image v-else :src="require('@/assets/images/auth2.png')" class="auth"></el-image>
              </li>
            </ul>
          </li>
        </ul>
      </el-tab-pane>
    </el-tabs>
    <div class="h-more">
      <el-button @click="lookMore">查看更多</el-button>
    </div>
  </div>
</template>

<script>
  import {
    dropDownSearchJobApi
  } from '@/api/home';
  export default {
    name: "hotJobs",
    props: ['jobList'],
    data() {
      return {
        unit_id: '',
        loading: false,
        jobItem: []
      }
    },
    watch: {
      //   监听数据变化
      unit_id: {
        handler: function () {
          this.getHotJobsList()
        },
        immediate: true
      }
    },
    methods: {
      handleClick(tab) {
        this.unit_id = tab.name
      },
      async getHotJobsList() {
        this.loading = true
        const {
          data: res
        } = await dropDownSearchJobApi({
          allocation: this.unit_id
        })
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        let jobArr = res.result.list
        this.jobItem = jobArr.slice(0, 6)
        this.loading = false
      },
      //查看更多
      lookMore() {
        this.$router.push('/pc/job')
      },
      //职业详情页
      toJobDetail(id) {
        this.$router.push(`/pc/job/detail?id=${id}`)
      }
    }
  }

</script>

<style lang="less" scoped>
  .hot-jobs {
    .h-title {
      font-size: 24px;
      text-align: center;
    }

    .h-title::after,
    .h-title::before {
      content: "";
      display: inline-block;
      vertical-align: 8px;
      margin: 0 10px;
      width: 50px;
      height: 1px;
      background: #d1d4db;
    }

    /deep/.is-active {
      color: gray;
    }

    /deep/.el-tabs__active-bar {
      background: @primaryColor;
    }

    /deep/.el-tabs__item:hover {
      color: gray;
    }

    .h-tabs {
      margin-top: 20px;

      .h-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .l-item {
          width: 384px;
          height: 132px;
          flex: 0 0 calc((100% - 20px) / 3);
          background: #fff;
          box-sizing: border-box;
          padding: 10px 20px;
          margin: 5px 0px;
          transition: box-shadow 1s;
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            box-shadow: 2px 5px 10px gray;
          }

          .i-info {
            //   height: 56px;
            font-size: 15px;

            .i-top {
              display: flex;
              justify-content: space-between;

              .t-money {
                color: #fd7240;
              }
              .jobName{
                width: 300px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
              }
            }

            .i-bottom {
              display: flex;
              color: #8d92a1;
              font-size: 12px;

              .b-item {
                // border-left: 1px solid #8d92a1;
                padding: 0px 10px;
                margin: 10px 0px;
                line-height: 12px;
              }

              .b-item:first-child {
                max-width: 190px;
                padding-left: 0px;
                border-left: none;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                white-space: nowrap;
              }
            }
          }

          .i-company {
            height: 56px;
            border-top: 1px solid #e4e7ed;
            display: flex;

            .c-item {
              display: flex;
              align-items: center;
              font-size: 13px;
              position: relative;
              width: 100%;

              .el-image {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                overflow: hidden;
              }

              .enterprise{
                position: absolute;
                right: 0;
              }

              .auth{
                border-radius: initial;
                width: 26px;
                height: auto;
                position: absolute;
                right: 35px;
              }

              p {
                margin: 0px 10px;
              }

              .c-text,
              .c-type {
                color: #8d92a1;
                padding: 0px 5px;
                // margin: 5px 0px;
                // line-height: 15px;
              }

              .c-type {
                border-left: 1px solid #8d92a1;
              }
            }
          }
        }
      }
    }

    .h-more {
      display: flex;
      justify-content: center;
      margin: 20px 0px;

      .el-button {
        background: @primaryColor;
        color: #fff;
      }

      .el-button:hover,
      .el-button:focus {
        border-color: @primaryColor;
      }
    }
  }

</style>
