<template>
  <div class="home">
    <!-- 搜索 -->
    <div class="m-search">
      <div class="s-container">
        <!-- <el-input type="text" placeholder="(例如：保安员)" v-model="searchVal">
          <el-select
            v-model="allocation"
            slot="prepend"
            placeholder="职位类型"
            clearable
          >
            <el-option
              :label="item.unit_nature"
              :value="index"
              v-for="(item, index) in jobList"
              :key="index"
            >
            </el-option>
          </el-select>
        </el-input>
        <el-button size="medium" @click="goSearch()">搜索</el-button> -->
        <!-- <ul class="s-list">
          <li
            class="l-item"
            v-for="(item, index) in jobList"
            :key="index"
            @click="goSearch(item.id)"
          >
            {{ item.unit_nature }}
          </li>
        </ul> -->
      </div>
    </div>
    <el-main>
      <div class="m-container">
        <ul class="c-main">
          <li class="m-student">
            <div class="m-content">
              <ul class="m-title clearfix">
                <li class="t-item">姓名</li>
                <li class="t-item">性别</li>
                <li class="t-item">年龄</li>
                <li class="t-item">身高(cm)</li>
                <li class="t-item">学历</li>
              </ul>
              <ul
                class="m-list clearfix"
                v-for="(item, index) in dataShow"
                :key="index"
                @click="goStudentDetail(item.user_id)"
              >
                <li class="l-item">{{item.user_nickname}}</li>
                <li class="l-item">{{ item.gender | genderFormat }}</li>
                <li class="l-item">{{ item.id_cart_num }}</li>
                <li class="l-item" v-if="item.height">{{ item.height }}</li>
                <li class="l-item" v-else>未填列</li>
                <li class="l-item" v-if="item.education_level">{{ item.education_level }}</li>
                <li class="l-item" v-else>未填列</li>
              </ul>
            </div>
            <ul class="m-footer">
              <li>{{ currentPage }}/{{ pageNum }}</li>
              <li class="m-txt">
                <el-button type="text" @click="lookMoreStudent()"
                >——查看更多——</el-button
                >
              </li>
              <li class="f-icon">
                <div class="i-left" @click="prePage">
                  <i class="fa fa-chevron-circle-left"></i>
                </div>
                <div class="i-right" @click="nextPage">
                  <i class="fa fa-chevron-circle-right"></i>
                </div>
              </li>
            </ul>
          </li>
          <li class="m-swiper">
            <swiperList></swiperList>
          </li>
        </ul>
      </div>
      <hot-jobs :jobList="jobList"></hot-jobs>
    </el-main>
  </div>
</template>

<script>
import {
  dropDownSearchStudentApi,
  selectInitJobApi
} from '@/api/home.js'
import hotJobs from './components/hotJobs.vue'
import swiperList from './components/swiperList.vue'
export default {
  name: 'home',
  components: {
    hotJobs,
    swiperList
  },
  data() {
    return {
      totalPage: [], // 所有分页的数据
      pageSize: 6, // 每页显示数量
      pageNum: 1, // 共几页=所有数据/每页现实数量
      dataShow: [], // 当前显示的数据
      currentPage: 1, // 默认当前显示第一页
      userList: [],
      searchVal: '', //搜索输入框的值
      allocation: '', //下拉选项值
      jobList: {}
    }
  },
  created() {
    this.getUserInfoList()
  },
  mounted() {
    this.getSelectInitJob()
  },
  methods: {
    //职位下拉数据初始化
    async getSelectInitJob() {
      const {
        data: res
      } = await selectInitJobApi()
      if (res.status != 10000) {
        this.$message.error(res.message)
      }
      this.jobList = res.result.allocation
    },
    //学员列表
    async getUserInfoList() {
      const {
        data: res
      } = await dropDownSearchStudentApi()
      if (res.status !== 10000) {
        return this.$message.error('获取学员列表数据失败')
      }
      this.userList = res.result.list
      this.pageNum = Math.ceil(this.userList.length / this.pageSize) || 1; //计算有多少页数据，默认为1
      // 循环页面
      for (let i = 0; i < this.pageNum; i++) {
        // 每一页都是一个数组 形如 [['第一页的数据'],['第二页的数据'],['第三页数据']]
        // 根据每页显示数量 将后台的数据分割到 每一页,假设pageSize为2， 则第一页是1-2条，即slice(0,2)，第二页是3-4条，即slice(3,4)以此类推
        this.totalPage[i] = this.userList.slice(this.pageSize * i, this.pageSize * (i + 1))
        //   0 0-6
        //   1 6-12
      }
      // 获取到数据后默认显示第一页内容
      this.dataShow = this.totalPage[this.currentPage - 1];
    },
    // 下一页
    nextPage() {
      if (this.currentPage === this.pageNum) return;
      this.dataShow = this.totalPage[this.currentPage];
      ++this.currentPage
    },
    // 上一页
    prePage() {
      if (this.currentPage === 1) return;
      this.dataShow = this.totalPage[this.currentPage - 2];
      --this.currentPage
    },
    //查看更多学员
    lookMoreStudent() {
      this.$router.push('/pc/student')
    },
    //跳转至职位列表页
    goSearch(allocation_id) {
      this.$router.push({
        name: 'job',
        params: {
          // searchVal: this.searchVal,
          allocation: allocation_id
        }
      })
    },
    //跳转至学员详情页
    goStudentDetail(user_id) {
      this.$router.push({
        name: 'studentDetail',
        params: {
          user_id: user_id
        }
      })
    }
  },
}

</script>

<style lang="less" scoped>
.home {
  background: #fafafa;
}

a {
  text-decoration: none;
}

.m-search {
  .s-container {
    width: 1200px;
    margin: 20px auto;
    background: #fff;
    border-radius: 5px;

    .s-list {
      width: 100%;
      height: 80px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      .l-item {
        line-height: 30px;
        border: 1px solid #ddd;
        padding: 0px 10px;
        font-size: 15px;
        margin-right: 20px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 2px 5px 10px gray;
        &:hover {
          color: #fff;
          background: @primaryColor;
          border: 1px solid transparent;
        }
      }
    }
    /deep/.el-input__inner {
      width: 100%;
      height: 50px;
      border: none;
      font-size: 16px;
    }
    .el-select {
      /deep/.el-input__inner {
        width: 120px;
        border: none;
        font-size: 16px;
      }
    }
    /deep/ .el-input-group__prepend {
      color: #fff;
      background: @primaryColor;

      .el-input__icon {
        color: #fff;
      }

      .el-input__inner::placeholder {
        color: #fff;
      }
    }

    .el-button {
      border-radius: 0px;
    }

    .el-input {
      width: 70%;
      height: 50px;
      border: 2px solid @primaryColor;
      border-right: 0px;
      border-radius: 5px 0px 0px 5px;
    }

    .el-button {
      height: 54px;
      color: #fff;
      font-size: 16px;
      background: @primaryColor;
      border: 2px solid @primaryColor;
      border-radius: 0 5px 5px 0;
    }
  }
}

.el-main {
  padding: 0;

  .m-container {
    width: 1200px;
    margin: 0px auto;

    .c-main {
      display: flex;
      justify-content: space-between;
    }

    .m-student {
      width: 33%;
      border-radius: 5px;
      background: #fff;
    }

    .m-swiper {
      width: 65%;
      height: 100%;
      border-radius: 5px;
      background: #fff;
    }

    .m-content {
      min-height: 340px;
      display: flex;
      flex-direction: column;
    }

    .m-title {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      background: #ddd;

      .t-item {
        float: left;
        width: 70px;
        text-align: center;
      }
    }

    .m-list {
      padding: 0 20px;

      &:hover {
        background: @primaryGray;
        color: gray;
      }

      .l-item {
        float: left;
        width: 70px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
        white-space: nowrap;
      }
    }

    .m-footer {
      border-top: 1px dashed #e6e6e6;
      height: 50px;
      line-height: 50px;
      padding: 0px 40px;
      display: flex;
      justify-content: space-between;

      .el-button {
        color: gray;
      }

      .f-icon {
        display: flex;
        font-size: 15px;

        .i-right {
          margin-left: 20px;
        }
      }
    }
  }

  .hot-jobs {
    width: 1200px;
    margin: 40px auto;
  }
}

.el-select-dropdown__item.selected {
  color: @primaryColor;
}
</style>
