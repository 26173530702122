<template>
  <div>
    <ul class="m-imags">
      <li class="l-img">
        <swiper :options="swiperOption" v-if="swiperList.length > 0">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <div class="s-img" @click="handleImg(item.url)">
              <img :src="item.img" v-if="index == 0 || index == swiperList.length - 1" />
              <img v-lazy="item.img" v-else />
            </div>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
      </li>
      <li class="r-img">
        <div class="i-first">
          <div class="i-item" v-for="(item, index) in images" :key="index" @click="handleImg(item.url)">
            <el-image :src="item.img" fit="fill" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import {
    swiper,
    swiperSlide
  } from "vue-awesome-swiper";
  import "swiper/dist/css/swiper.css";
  import {
    getSwiperListApi
  } from '@/api/home'
  export default {
    name: "swiperList",
    data() {
      return {
        swiperOption: {
          loop: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          // 显示分页
          pagination: {
            el: ".swiper-pagination",
            clickable: true, //允许分页点击跳转
          },
          // 设置点击箭头
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        },
        swiperList: [], //轮播图列表
        images: [], //右侧图片
        tokenStr: '',
      }
    },
    components: {
      swiper,
      swiperSlide
    },
    mounted() {
      this.getSwiperList()
    },
    methods: {
      async getSwiperList() {
        const {
          data: res
        } = await getSwiperListApi()
        let swipers = res.result.list
        this.swiperList = _.filter(swipers, {
          gid: 1
        })[0].imgurl
        this.images = _.filter(swipers, {
          gid: 2
        })[0].imgurl
      },
      handleImg(url) {
        const tokenStr = localStorage.getItem('school_token')
        if (!tokenStr) {
          this.$store.commit('saveloginStatus', 2)
          this.$store.commit('saveloginDialog', true)
        } else {
          window.open(url, '_parent')
        }
      }
    },
  }

</script>

<style lang="less" scoped>
  .m-imags {
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    width: 780px;
    height: 386px;

    .l-img {
      width: 515px;
      height: 386px;

      .swiper-container {
        position: relative;
        width: 515px;
        height: 386px;

        .swiper-slide {
          line-height: 386px;
          color: rgb(255, 255, 255);
          font-size: 16px;
          text-align: center;
        }

        .swiper-slide:hover {
          cursor: pointer;
        }

        .s-img {
          width: 100%;
          height: 100%;
        }
      }

      /deep/ .swiper-button-prev,
      .swiper-button-next {
        width: 12px;
        background-size: 12px 44px;
      }

      /deep/ .swiper-pagination-bullet-active {
        background: @primaryGray;
      }
    }

    .r-img {
      .i-first {
        width: 264px;
        height: 386px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .i-item {
          height: 128px;
        }

        .i-item:hover {
          cursor: pointer;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

</style>
